<template>
  <div>
    <Header></Header>
    <div>
      <div class="container">
        <div class="login-form-box" style="max-width: 560px">
          <div class="box-head">
            <strong>Account Verification</strong>
            <div class="dots"></div>
          </div>
          <div class="box-content">
            <div class="verify-alert" v-if="verifyStatus == 0">
              <div class="icon">
                <b-spinner variant="light" large></b-spinner>
              </div>
              <strong>Please wait...</strong>
            </div>
            <div class="verify-alert" v-if="verifyStatus == 1">
              <div class="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                  />
                  <path
                    d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                  />
                </svg>
              </div>
              <strong>Your account is verified!</strong>
              <p>Please go back to login.</p>
              <router-link class="main-btn" to="/login">Go to login</router-link>
            </div>
            <div class="verify-alert danger" v-if="verifyStatus == 2">
              <div class="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                  />
                  <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </div>
              <strong>Your account is not verified!</strong>
              <p>Please try again.</p>
              <router-link class="main-btn" to="/login">Go to login</router-link>
            </div>
          </div>
        </div>

        <div class="login-form-bottom" style="max-width: 560px">
          Didn't receive verification email? <a @click="resendMail()" style="cursor: pointer;">Resend Mail.</a>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../../layouts/components/HeaderComp.vue";
import { BSpinner } from "bootstrap-vue";
import Footer from "../../layouts/components/FooterComp.vue";
export default {
  components: {
    Header,
    Footer,
    BSpinner,
  },
  data() {
    return {
      hashData: [],
      verifyStatus: 0,
    };
  },
  mounted() {
    this.hashData = window.location.hash.substring(1).split(";");
    this.verifyEmail();
  },
  methods: {
    verifyEmail() {
      console.log(this.hashData);

      const verifyData = {
        email: this.hashData[1],
        token: this.hashData[0],
        timestamp: this.hashData[2],
      };

      axios
        .post(`auth/verify-email`, verifyData)
        .then((res) => {
          console.log(res.data);
          this.verifyStatus = 1;
        })
        .catch((err) => {
          this.errors.push(err);
          this.verifyStatus = 2;
        });
    },

    resendMail() {
      axios
        .post(`auth/resend-verification-email`, { email: this.hashData[1] })
        .then((res) => {
          console.log(res.data);
          this.$toast.open({
            message: res.data.message,
            type: "success",
            position: "top-right",
          });
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },
  },
};
</script>

<style></style>
